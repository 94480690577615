import React from "react";
import instagram from '@img/instagram_blanco.webp';
import fb from '@img/fb_blanco.webp';
import logo from '@img/logo_blanco.webp';
import '@styles/Footer.scss';
import { NavLink } from "react-router-dom";


const Footer = () =>{
    return (
        <div className="Footer">
            <div className="Footer-detail">
                <div className="contact">
                    <div className="terms-title">
                        Redes sociales
                    </div>
                    <div className="terms-body">
                        <img className="socialmedia" src={instagram} alt="instagram-firulife" onClick={()=>  window.open('https://www.instagram.com/firu.firulife/', '_blank')}/>
                        <img className="socialmedia" src={fb} alt="instagram-firulife" onClick={()=>  window.open('https://www.facebook.com/profile.php?id=61558168424721', '_blank')}/>
                    </div>
                </div>
                <div className="contact">
                    <div className="terms-title">
                        Contáctanos
                    </div>
                    <div className="terms-body">
                        <span className="text">Correo: firufirulife@gmail.com</span>
                    </div>
                </div>
                <div className="terms">
                    <div className="terms-title">
                        Documentos
                    </div>
                    <div className="terms-body">
                        <NavLink className="link_terms" to='/tyc' onClick={() => window.scrollTo(0, 0)}>Términos y condiciones</NavLink>
                        <NavLink className="link_terms" to='/pdp' onClick={() => window.scrollTo(0, 0)}>Políticas de privacidad</NavLink>
                    </div>
                </div>
            </div>
            <div className="Footer-icon">
                <img src={logo} alt="icono-firulife"/>
                <div className="footer-copyright">© Firulife 2024 todos los derechos reservados</div>
            </div>
        </div>
    )
}

export default Footer;